<template>
  <div class="page-contain">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <div class="page-table">
      <div class="content-cont">
        <div class="forms-btns-edit">
          <el-button type="primary" v-if="editType == 'look'" size="small" @click="edit">编辑</el-button>
        </div>
        <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            size="medium"
            label-width="160px"
          >
            <div> 
              <el-row>
                <el-col :span="24">
                  <el-form-item label="主体名称：" prop="bodyName">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.bodyName }}</p>
                    <el-input clearable v-else v-model="ruleForm.bodyName" placeholder="有营业执照的填企业名称，没有的填负责人姓名。" maxlength=64 show-word-limit type="textarea" :rows="3"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="简称：" prop="simpleName">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.simpleName }}</p>
                    <el-input clearable v-else v-model="ruleForm.simpleName" placeholder="请输入简称" maxlength=10 show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="主体编号：" prop="bodyCode">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.bodyCode }}</p>
                    <el-input clearable v-else v-model="ruleForm.bodyCode" placeholder="请输入主体编号" :disabled="true"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="上级管理或控股公司：" prop="parentBodyName">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.parentBodyName }}</p>
                    <el-input :disabled="true" v-else class="my-input-btn" v-model="ruleForm.parentBodyName" placeholder="请慎重选择，选择后该主体可以查看您所有信息。">
                      <el-button slot="append" @click="chooseType('condition')" type="primary">选择</el-button>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="所属区域：" prop="address">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.address }}</p>
                    <el-input class="my-input-btn my-input-btn1" v-else :disabled="true" placeholder="请选择" suffix-icon="el-icon-arrow-down" @click.native="chooseType('getpickarea')" v-model="ruleForm.address"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="社区及门牌地址：" prop="doorNumber">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.doorNumber }}</p>
                    <el-input clearable v-else v-model="ruleForm.doorNumber" @change="inputChange" placeholder="请输入社区及门牌地址" maxlength=32 show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="经营地址：" prop="businessAddress">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.businessAddress }}</p>
                    <el-input clearable v-else v-model="ruleForm.businessAddress" placeholder="自动生成" :disabled="true"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="经度：" prop="longitude">
                    <!-- <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.longitude }}</p> -->
                    <el-input :disabled="true" class="my-input-btn my-input-btn2" v-model="ruleForm.longitude" :placeholder="editType == 'look'? '' : '请选择基地经度'">
                      <el-button slot="append" @click="chooseType('getamap')" type="primary">定位</el-button>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="纬度：" prop="latitude">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.latitude }}</p>
                    <el-input :disabled="true" v-else v-model="ruleForm.latitude" placeholder="请选择纬度"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="注册地址：" prop="registerAddress">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.registerAddress }}</p>
                    <el-input clearable v-else v-model="ruleForm.registerAddress" placeholder="请输入注册地址" maxlength=64 show-word-limit type="textarea" :rows="3"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="主体类别：" prop="bodyType">
                    <el-radio-group v-model="ruleForm.bodyType" @change="change1" :disabled="editType == 'look'">
                      <el-radio
                        v-for="(item, index) in selectObj.bodyType"
                        :key="index"
                        :label="item.value"> {{ item.label }}
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="ruleForm.bodyType != '12'">
                <el-col :span="24">
                  <el-form-item label="生产主体类型：" prop="productionBodyType">
                    <el-radio-group v-model="ruleForm.productionBodyType" @change="change2" :disabled="editType == 'look'">
                      <el-radio
                        v-for="(item, index) in selectObj.productionBodyType"
                        :key="index"
                        :label="item.value"> {{ item.label }}
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="rules.retail[0].required">
                <el-col :span="24">
                  <el-form-item label="是否散户：" prop="retail">
                    <el-radio-group v-model="ruleForm.retail"  @change="initRequired" :disabled="editType == 'look'">
                      <el-radio
                        v-for="(item, index) in selectObj.retail"
                        :key="index"
                        :label="item.value"> {{ item.label }}
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="ruleForm.bodyType != '11'">
                <el-col :span="24">
                  <el-form-item label="经营主体类型：" prop="businessBodyType">
                    <el-radio-group v-model="ruleForm.businessBodyType" @change="initRequired" :disabled="editType == 'look'">
                      <el-radio
                        v-for="(item, index) in selectObj.businessBodyType"
                        :key="index"
                        :label="item.value"> {{ item.label }}
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="ruleForm.bodyType != '12'">
                <el-col :span="24">
                  <el-form-item label="经营范围：" prop="pro1">
                    <el-checkbox-group v-model="ruleForm.pro1" :disabled="editType == 'look'">
                      <el-checkbox v-for="(item, index) in selectObj.pro2" :label="item.value" :key="index">{{item.label}}</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="ruleForm.bodyType != '11' && ruleForm.businessBodyType == '11'">
                <el-col :span="24">
                  <el-form-item label="所属市场：" prop="bazaar">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.bazaar }}</p>
                    <el-input clearable v-else v-model="ruleForm.bazaar" placeholder="请输入所属市场" maxlength=32 show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="ruleForm.bodyType != '11' && ruleForm.businessBodyType == '11'">
                <el-col :span="24">
                  <el-form-item label="摊位号：" prop="stall">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.stall }}</p>
                    <el-input clearable v-else v-model="ruleForm.stall" placeholder="请输入摊位号" maxlength=16 show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- <el-row v-if="ruleForm.retail != '01'">
                <el-col :span="24">
                  <el-form-item label="主体名称：" prop="bodyName">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.bodyName }}</p>
                    <el-input clearable v-else v-model="ruleForm.bodyName" :placeholder="ruleForm.retail == '01' ? '请输入主体名称' : '请输入企业名称'" maxlength=64 show-word-limit type="textarea" :rows="3"></el-input>
                  </el-form-item>
                </el-col>
              </el-row> -->
              <el-row>
                <el-col :span="24">
                  <el-form-item :label="ruleForm.retail == '01' ? '姓名：' : '法人：'" prop="representativeName">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.representativeName }}</p>
                    <el-input clearable v-else v-model="ruleForm.representativeName" :placeholder="ruleForm.retail == '01' ? '请输入姓名' : '请输入法人姓名'" maxlength=16 show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <div :class="ruleForm.retail == '01' ? 'cont-source' : ''">
                <!-- <p class="cont-source-title" v-if="ruleForm.retail == '01'"><span class="tips-red">*</span>关键信息</p> -->
                <el-row>
                  <el-col :span="24">
                    <el-form-item :label="ruleForm.retail == '01' ? '身份证号：' : '社会信用代码：'" prop="creditCode">
                      <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.creditCode }}</p>
                      <el-input clearable v-else v-model="ruleForm.creditCode" :placeholder="ruleForm.retail == '01' ? '请输入身份证号' : '请输入社会信用代码'" maxlength=18 show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="注册手机号码：" prop="mobilePhoneNumber">
                      <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.mobilePhoneNumber }}</p>
                      <el-input clearable v-else v-model="ruleForm.mobilePhoneNumber" placeholder="请输入注册手机号码" maxlength=14 show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <el-row v-if="editType != 'look'">
                <el-col :span="24">
                  <el-form-item label="手机验证码：" prop="verificationCode">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.verificationCode }}</p>
                    <el-input v-else :class="sendFlag ? 'my-input-btn' :  'my-input-btn-gray'" v-model="ruleForm.verificationCode" placeholder="请输入手机验证码">
                      <el-button slot="append" v-if="sendFlag" @click="sendMsg('mobilePhoneNumber')" type="primary">获取验证码</el-button>
                      <el-button slot="append" v-else class="btn-gray"  type="primary">{{countdown}}秒后重新发送</el-button>
                    </el-input>
                  </el-form-item>
                </el-col>
                <p class="tips-gray">验证码10分钟有效</p>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="办公室电话：" prop="officePhoneNumber">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.officePhoneNumber }}</p>
                    <el-input clearable v-else v-model="ruleForm.officePhoneNumber" placeholder="请输入办公室电话" maxlength=32 show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="联系人：" prop="linkman">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.linkman }}</p>
                    <el-input clearable v-else v-model="ruleForm.linkman" placeholder="请输入联系人" maxlength=16 show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="联系人手机：" prop="linkmanPhoneNumber">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.linkmanPhoneNumber }}</p>
                    <el-input clearable v-else v-model="ruleForm.linkmanPhoneNumber" placeholder="请输入联系人手机" maxlength=14 show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="单位网址：" prop="bodyUrl">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.bodyUrl }}</p>
                    <el-input clearable v-else v-model="ruleForm.bodyUrl" placeholder="请输入单位网址" maxlength=256 show-word-limit type="textarea" :rows="3"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="电子邮箱等信息：" prop="email">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.email }}</p>
                    <el-input clearable v-else v-model="ruleForm.email" placeholder="请输入电子邮箱等信息" maxlength=32 show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="单位简介：" prop="bodyAbstract">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.bodyAbstract }}</p>
                    <el-input clearable v-else v-model="ruleForm.bodyAbstract" placeholder="请输入单位简介" maxlength=600 show-word-limit type="textarea" :rows="3"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-form>
        </div>
        <div class="forms-btns">
          <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm()">保存</el-button>
          <el-button @click="closeModel" :disabled="loading" v-if="editType != 'look'" size="small"> 取消</el-button>
        </div>
      </div>
    </div>
    <pick-area ref="getpickarea" showBtn="two3" @getpickarea="getpickarea" needRequest></pick-area>
    <getamap ref="getamap" @getamap="getamap"></getamap>
    <condition ref="condition" @getcondition="getcondition" tempType="01"></condition>
  </div>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import pickArea from '@/views/common/pickArea.vue'
import breadcrumb from '@/components/breadcrumb.vue'
import condition from '@/views/common/condition.vue'
import getamap from '@/views/common/getamap2.vue'
let _t = null
export default {
  name: "maininfo",
  components: {
    pickArea,
    breadcrumb,
    condition,
    getamap
  },
  props:{
  },
  data() {
    return {   
      breadStaticData:['主体信息', '主体信息'],
      linkData:[],
      thirdStaticData:[],
      rules: {
        address: [{ required: true,  trigger: "change", message: '请选择所属区域'}],
        retail: [{ required: true,  trigger: "change", message: '请选择是否散户'}],
        bodyName: [{ required: true,  trigger: "change", message: '请输入主体名称'}],
        representativeName: [{ required: true,  trigger: "change", message: '请输入'}],
        bodyType: [{ required: true,  trigger: "change", message: '请选择主体类别'}],
        productionBodyType: [{ required: true,  trigger: "change", message: '请选择生产主体类型'}],
        businessBodyType: [{ required: true,  trigger: "change", message: '请选择经营活动'}],
        pro1: [{ required: true,  trigger: "change", message: '请选择'}],
        stall: [{ required: false,  trigger: "change", message: '请输入摊位号'}],
        bazaar: [{ required: false,  trigger: "change", message: '请输入所属市场'}],
        verificationCode: [{ required: true,  trigger: "change", message: '请输入验证码'}],
        bodyUrl: [{ required: false, trigger: "change", 
          validator: (rule, val, callback) => {
            if(rule.required && !val){
              callback(new Error("请输入单位网址"))
            }else if(val && !baseValidate.url(val)){
              callback(new Error("请输入正确单位网址"))
            }else{
              callback()
            }
          }
        }],
        mobilePhoneNumber: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(rule.required && !val){
              callback(new Error("请输入手机号"))
            }else if(val && !baseValidate.tel(val)){
              callback(new Error("请输入正确的手机号"))
            }else{
              callback()
            }
          }
        }],
        linkmanPhoneNumber: [{ required: false, trigger: "change", 
          validator: (rule, val, callback) => {
            if(rule.required && !val){
              callback(new Error("请输入手机号"))
            }else if(val && !baseValidate.tel(val)){
              callback(new Error("请输入正确的手机号"))
            }else{
              callback()
            }
          }
        }],
        creditCode: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(rule.required && !val){
              callback(new Error("请输入"))
            }else{
              if(!rule.required && !val) callback()

              let crecode = _t.ruleForm.creditCode || '',
                len = crecode?.length
              if(_t.ruleForm.pro1 == '00'){
                if(!(len == 15 || len == 18)){
                  callback(new Error("请输入正确的统一社会信用代码或身份证"))
                }
              }else{
                if(_t.ruleForm.retail != '01'){
                  if(!(len == 15 || len == 18)){
                    callback(new Error("请输入正确的统一社会信用代码"))
                  }
                }else{
                  if(len != 18){
                    callback(new Error("请输入正确的身份证号码"))
                  }
                }
              }
              callback()
            }
          }
        }]
      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: 'look',
      ruleForm: {
        pro1: []
      },
      initForm:{},
      selectObj: {
        pro2: CONSTPARAMS.businessRange,
        bodyType: CONSTPARAMS.bodyType,
        productionBodyType:  CONSTPARAMS.productionBodyType,
        businessBodyType: CONSTPARAMS.businessBodyType,
        retail: CONSTPARAMS.retail
      },
      addresses: [],
      countdown: CONSTPARAMS.countdown,
      sendFlag: true,
      defaultAddress: []
    };
  },
  created() {
    this.getInfo()
	},
  mounted() {
    _t = this
  },
  methods: {
    getamap(data){
      this.ruleForm.longitude = data.long
      this.ruleForm.latitude = data.lat
    },
    edit(){
      this.editType = 'add'
    },
    getInfo(){
      let _this = this
      request.psfindmainbody({
        bodyId: utils.getBodyId()
      }).then(res => {
        if(res.code == 200){
          let result = res.data
          if(!result){
            _this.message('此条数据信息获取为空！', "error")
            return
          }
          result = {
            ...result,
            issueTime: result.issueTime ? utils.dateFormat(result.issueTime, 'yyyy-MM-dd') : '',
            abortTime: result.abortTime ? utils.dateFormat(result.abortTime, 'yyyy-MM-dd') : ''
          }
          delete result.createTime

          let obj = res.data, arr = []
          obj.plant == '1' && arr.push('plant')
          obj.farming == '1' && arr.push('farming')
          obj.aquatic == '1' && arr.push('aquatic')
          obj.pro1 = arr
          obj.address = (obj.province + '') + (obj.city + '') + (obj.county + '') + (obj.town + '') + (obj.village + '')
          obj.pro3 = utils.getAddresByObj(obj)
          obj.retail = obj.retail || '02'
          obj.longitude = obj.longitude
          obj.latitude = obj.latitude
          _this.ruleForm = {
            ...obj
          }
          _this.initForm = {
            ...obj
          }
          this.defaultAddress = utils.getAddressByObj(obj)
          _this.initRequired()
        } else {
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    getcondition(data){
      this.ruleForm.parentBodyId = data.bodyId || ''
      this.ruleForm.parentBodyName = data.bodyName || ''
    },
    sendMsg(prop){
      utils.sendMsg(this, prop, 'main')
    },
    change1(val){
      if(val == '11'){
        this.ruleForm.businessBodyType = ''
        this.ruleForm.retail = '02'
      }else if(val == '12'){
        this.ruleForm.pro1 = []
        this.ruleForm.productionBodyType = ''
        this.ruleForm.retail = ''
      }else if(val == '13'){
        this.ruleForm.businessBodyType = ''
        this.ruleForm.productionBodyType = ''
        this.ruleForm.retail = '02'
      }
      this.initRequired()
    },
    change2(val){
      if(val == '05'){
        this.ruleForm.retail = '01'
      }else{
        this.ruleForm.retail = '02'
      }
      this.initRequired()
    },
    change3(val){
      if(val == '04'){
        this.ruleForm.retail = '01'
      }else{
        this.ruleForm.retail = '02'
      }
      this.initRequired()
    },
    initRequired(){
      let _this = this,
        contObj = _this.ruleForm,
        bodyType = contObj.bodyType,
        businessBodyType = contObj.businessBodyType,
        retail = contObj.retail
      if(bodyType == '11'){ //生产主体
        this.rules.productionBodyType[0].required = true
        this.rules.businessBodyType[0].required = false
        this.rules.retail[0].required = false
        this.rules.pro1[0].required = true
        
      }else if(bodyType == '12'){ //经营主体
        this.rules.productionBodyType[0].required = false
        this.rules.businessBodyType[0].required = true
        this.rules.retail[0].required = true
        this.rules.pro1[0].required = false
      }else if(bodyType == '13'){ //生产/经营主体
        this.rules.productionBodyType[0].required = true
        this.rules.businessBodyType[0].required = true
        this.rules.retail[0].required = false
        this.rules.pro1[0].required = true
      }else if(bodyType == '41'){
        this.rules.productionBodyType[0].required = false
        this.rules.businessBodyType[0].required = true
        this.rules.retail[0].required = false
      }else{
        this.rules.productionBodyType[0].required = true
        this.rules.businessBodyType[0].required = true
        this.rules.retail[0].required = true
        this.rules.pro1[0].required = true
      }
      if(bodyType != '11' && businessBodyType == '11'){
        this.rules.bazaar[0].required = true
        this.rules.stall[0].required = true
      }else{
        this.rules.bazaar[0].required = false
        this.rules.stall[0].required = false
      }

      if(retail == '01'){
        // this.rules.bodyName[0].required = false
        // this.rules.creditCode[0].required = false
        // this.rules.mobilePhoneNumber[0].required = false
      }else{
        // this.rules.bodyName[0].required = true
        // this.rules.creditCode[0].required = true
        // this.rules.mobilePhoneNumber[0].required = true
      }
    },
    chooseType(name){
      if(name == 'getpickarea'){
        this.$refs[name].initData(utils.getAddressByObj(this.ruleForm) || [])
        return
      }else if(name == 'getamap'){
        if(this.editType == 'look' && !this.ruleForm.longitude && !this.ruleForm.latitude){
          this.message('无经纬度信息', "warning")
          return
        }
        this.$refs[name].initData(this.ruleForm.longitude, this.ruleForm.latitude, this.editType)
        return
      }
      this.$refs[name].initData()
    },
    inputChange(val){
      this.ruleForm.businessAddress = (this.ruleForm.address || '') + (val || '')
    },
    getpickarea(data){
      let address = ''
      data.forEach(cur => {
        if(cur.areaCode){
          address += cur.areaName
        }
      })
      this.ruleForm.province = data[0] && data[0].areaName || ''
      this.ruleForm.provinceCode = data[0] && data[0].areaCode || ''
      this.ruleForm.city = data[1] && data[1].areaName || ''
      this.ruleForm.cityCode = data[1] && data[1].areaCode || ''
      this.ruleForm.county = data[2] && data[2].areaName || ''
      this.ruleForm.countyCode = data[2] && data[2].areaCode || ''
      this.ruleForm.town = data[3] && data[3].areaName || ''
      this.ruleForm.townCode = data[3] && data[3].areaCode || ''
      this.ruleForm.village = data[4] && data[4].areaName || ''
      this.ruleForm.villageCode = data[4] && data[4].areaCode || ''
      this.ruleForm.address = address || ''
      this.ruleForm.businessAddress = address + (this.ruleForm.doorNumber || '')
    },
    setAddress(data = {}){
      this.ruleForm.province = data.province || ''
      this.ruleForm.provinceCode = data.provinceCode || ''
      this.ruleForm.city = data.city || ''
      this.ruleForm.cityCode = data.cityCode || ''
      this.ruleForm.county = data.county || ''
      this.ruleForm.countyCode = data.countyCode || ''
      this.ruleForm.town = data.town || ''
      this.ruleForm.townCode = data.townCode || ''
      this.ruleForm.village = data.village || ''
      this.ruleForm.villageCode = data.villageCode || ''
      this.ruleForm.address = utils.getAddressByObj(data, true)
      this.$refs.getpickarea.resetAddress(utils.getAddressByObj(data))
    },
    async initData(title, editType, ruleForm, transobj = {}){
      if(editType == 'add'){
        let id = await utils.getNewId(),
          obj = {
          ...this.initForm,
          ...transobj,
          baseId: id,
          baseProductList: []
        }
        this.addresses = []
        this.ruleForm = obj
      }else{
        this.ruleForm = { 
          ...ruleForm,
          baseProductName: this.getRealLables(ruleForm.baseProductList),
        }
      }
      this.title = title
      this.editType = editType
      this.closeFlag = true
      this.resetForm()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
    },
    initAdd(){
    },
    initEdit(){
      this.addresses = utils.getAddressByObj(this.ruleForm)
    },
    initLook(){
      this.initEdit()
    },
    async submitForm() {
      let _this = this,
        formName = 'ruleForm'
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.submitData()
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    async submitData(){
      let _this = this,
        contObj = this.ruleForm

      _this.loading = true
      let flag = await utils.codeCheck('main', contObj.mobilePhoneNumber, contObj.verificationCode) || false
      if(!flag) {
        _this.loading = false 
        return
      }

      // if(contObj.retail == '01'){
      //   contObj.bodyName = contObj.representativeName
      // }

      //生产基地
      contObj.plant = '0'
      contObj.farming = '0'
      contObj.aquatic = '0'
      const pro1 = contObj.pro1 || []
      pro1.length && pro1.forEach(cur => {
        contObj[cur] = '1'
      })
      
      request.psupdatemainbody({ ...contObj, pro1: '' }).then((res) => {
        if(res.code == 200){
          _this.message('保存成功！', "success")
          _this.initForm = {
            ...contObj
          }
          _this.editType = 'look'
          request.commonuserinfo({
            'userName': utils.getUserName()
          }).then(res2 => {
            if(res2.code == 200){
              utils.setStore('userInfo', res2.data)
            }else{
              _this.message(res2.msg || '', "error")
            }
          }).catch(e => {
            console.log(e)
          })

        }else{
          _this.message(res.msg || '', "error")
        }
        _this.loading = false
      }).catch(e => {
        console.log(e)
        _this.loading = false
      })

    },
    closeModel() {
      this.ruleForm = {
        ...this.initForm
      }
      this.resetForm()
      this.editType = 'look'
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
  .forms-btns-edit{
    text-align: right;
    padding-right: 10px;
    padding-bottom: 30px;
  }
  .forms-form{
    overflow: auto;
    height: calc(100% - 118px);
    padding-right: 40px;
  }
  .content-title{
    padding: 10px 0 40px;
    text-align: center;
    font-size: 20px;
  }
  .content-cont{
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    padding: 0 50px 20px 0;
    height: 100%;
    box-sizing: border-box;
  }
  .el-row{
    padding: 5px;
  }
  .tips-gray{
    margin-left: 40px;
  }
  .forms-btns {
    text-align: center;
    padding: 30px 20px 10px;
  }
</style>


